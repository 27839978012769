import Player from '@vimeo/player';

let video;
const parentEl = document.getElementById('video-hero');
const overlay = document.getElementById('video-hero-overlay');
const container = document.getElementById('video-hero-container');
const playButton = document.getElementById('video-hero-play');
const activeClass = 'video-hero-is-playing';

function play() {
  parentEl.classList.add(activeClass);
  video.play();
}

function pause() {
  parentEl.classList.remove(activeClass);
  video.pause();
}

function toggle() {
  video.getPaused().then(function(paused) {
    paused ? play() : pause();
  });
}

function init() {
  const url = container.dataset.url;
  const interactive = container.dataset.interactive;
  const isBackground = interactive === 'true' ? false : true;
  if (!url) {
    console.log('empty url');
    return;
  }

  video = new Player(container, {
    url: url,
    background: isBackground,
    transparent: false,
    byline: false,
    title: false,
    controls: false
  });

  video.ready().then(function() {
    playButton.addEventListener('click', play);

    if (interactive) {
      overlay.addEventListener('click', toggle)
    }
  });
}

export default function() {
  if (!container) {
    return;
  }
  init();
}
